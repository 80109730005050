.skeleton {
  position: relative;
  overflow: hidden;
  /* background: rgb(238, 237, 237); */
  background: rgb(240, 240, 240);
  margin-bottom: 10px;
}
.skeleton__info-dashlet {
  height: 150px;
  width: 250px;
}
.skeleton__resource-item {
  border-radius: 10px;
  height: 50px;
  width: 100%;
}
.skeleton::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  animation: sweep 1.25s infinite;
  background-image: linear-gradient(to left, transparent, #c7c7c752, transparent);
}
@keyframes sweep {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(-100%);
  }
}
