$body-text: #404040;

//$greener: #a3d83f;

$greener: #96c63d;

$green: #387a3b;

//Responsive Typography

@media (max-width: 1200px) {
  legend {
    font-size: calc(1.275rem + 0.3vw);
  }
  h1,
  .h1 {
    font-size: calc(1.375rem + 1.5vw);
  }
  h2,
  .h2 {
    font-size: calc(1.325rem + 0.9vw);
  }
  h3,
  .h3 {
    font-size: calc(1.3rem + 0.6vw);
  }
  h4,
  .h4 {
    font-size: calc(1.275rem + 0.3vw);
  }
  .display-1 {
    font-size: calc(1.725rem + 5.7vw);
  }
  .display-2 {
    font-size: calc(1.675rem + 5.1vw);
  }
  .display-3 {
    font-size: calc(1.575rem + 3.9vw);
  }
  .display-4 {
    font-size: calc(1.475rem + 2.7vw);
  }
  .close {
    font-size: calc(1.275rem + 0.3vw);
  }
}

//Responsive Typography

/* Styles for Responsive Width */

.w-100 {
  width: 100% !important;
}
.w-80 {
  width: 80% !important;
}
.w-75 {
  width: 75% !important;
}
.w-60 {
  width: 60% !important;
}
.w-50 {
  width: 50% !important;
}
.w-40 {
  width: 40% !important;
}
.w-25 {
  width: 25% !important;
}
.w-20 {
  width: 20% !important;
}

@media (max-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-80 {
    width: 80% !important;
  }
  .w-sm-75 {
    width: 75% !important;
  }
  .w-sm-60 {
    width: 60% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-40 {
    width: 40% !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-20 {
    width: 20% !important;
  }
}

@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-80 {
    width: 80% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
  .w-md-60 {
    width: 60% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-40 {
    width: 40% !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-20 {
    width: 20% !important;
  }
}

@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-80 {
    width: 80% !important;
  }
  .w-lg-75 {
    width: 75% !important;
  }
  .w-lg-60 {
    width: 60% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-40 {
    width: 40% !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-20 {
    width: 20% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-80 {
    width: 80% !important;
  }
  .w-xl-75 {
    width: 75% !important;
  }
  .w-xl-60 {
    width: 60% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-40 {
    width: 40% !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-20 {
    width: 20% !important;
  }
}

/* End of Styles for Responsive Width */
