.required::after {
  content: "*";
  color: red;
  font-size: 20px;
}
.actions {
  position: absolute;
}
.actions:hover {
  cursor: pointer;
}
.actions__item:hover {
  cursor: pointer;
  background-color: lightgray;
}
.actions__content {
  display: none;
  list-style-type: none;
  position: absolute;
  top: 15px;
  right: -15px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  z-index: 2;
  padding: 0;
}
.actions__item > span {
  padding: 0.5rem 0.75rem;
}
.actions__item:last-child {
  border-top: 1px solid lightgray;
}
.actions:hover .actions__content {
  display: block;
}
.trash {
  border-left: 1px solid lightgray;
  height: 3rem;
  margin-right: 1rem;
}

.input-prepend {
  position: relative;
  right: -25px;
}
.input-prepend + input {
  padding-left: 2.25rem;
}
.resource-row {
  font-size: 1rem;
  padding: 0.125rem 0;
  border-bottom: 0.75px solid rgba(0, 0, 0, 0.1);
}
