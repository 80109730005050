@import './variables.scss';

body {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: $body-text !important;
}

html {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
}

/* ---------------------------------------------------
    FIXES
----------------------------------------------------- */
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.raise-up {
  z-index: 1;
}

.bring-down {
  z-index: -3;
}

.hidden {
  display: none;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.5s;
}

.active-nav {
  background-color: #ffffff;
  border-bottom: 4px solid $greener;
}

.fix-height {
  height: 70vh;
  overflow-y: scroll;
}

.fix-height50 {
  height: 50vh;
}

.icon-set {
  position: relative;
  left: 1.5em;
}

/* .opacity {
  opacity: 0.7;
} */

/* ---------------------------------------------------
    TABLE STYLE
----------------------------------------------------- */

// table {
//   table-layout: auto;
// }
// table .thead-secondary th {
//   color: #fff;
//   background-color: #4f4f4f;
// }

.MuiTableCell-root {
  font-family: 'Nunito', sans-serif !important;
  font-weight: bold;
}

.MuiTableCell-head {
  font-size: 1rem;

  :hover {
    color: #fff;
  }
}
.MuiTableSortLabel-icon {
  :hover {
    color: #fff !important;
  }
}

.MuiCircularProgress-colorPrimary {
  color: #96c63d !important;
}

/* ---------------------------------------------------
    NAVIGATION STYLE
----------------------------------------------------- */

.navbar {
  background: #f8f8f8;
  border-radius: 5px;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.default-profile {
  background: $greener;
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.chartbar {
  color: #525252 !important;
}

.red {
  color: #dd4b39;
}
.green {
  color: $green !important;
}

.greener {
  color: $greener;
}

.bg-expend {
  background: #c66868;
}

.bg-revenue {
  background: $greener;
}
.bg-revenue-light {
  background: #96c63d36;
}

.bg-revenue-dark {
  background: $green;
}
.bg-field {
  background-image: url('../assets/fields.svg');
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}
/* ---------------------------------------------------
    TEXT STYLE
----------------------------------------------------- */

i,
span {
  display: inline-block;
}

h2 {
  color: #808080;
  font-weight: 800;
}

h3 {
  color: #808080;
  font-weight: 800;
}

h4 {
  color: #808080;
  font-weight: 600;
  line-height: normal;
}
h5 {
  color: $body-text;
}
h6 {
  color: $body-text;
}
hr {
  margin: 0;
}
.thick {
  font-weight: 800;
}
.semibold {
  font-weight: 700;
}
.underline {
  text-decoration: underline;
}
.b {
  font-weight: bold;
}

.curved {
  border-radius: 20px;
}

.p-text {
  font-size: 0.8em;
}

.text-revenue {
  color: $greener;
}
.font-fix {
  font-size: 1em;
}

.small-font-fix {
  font-size: 0.8em;
}

.font-18 {
  font-size: 1.3em;
}
.font-24 {
  font-size: 2em;
}
.font-30 {
  font-size: 2.5em;
}

/* ---------------------------------------------------
    BUTTON STYLE
----------------------------------------------------- */

.btn-expend {
  background: #ff9326;
}

.btn-revenue {
  background: $greener;
}

.btn-outline-revenue {
  border: 1px solid $greener;
  color: $greener;
}

.btn-edit {
  background: linear-gradient(0deg, #63c2f6, #63c2f6);
  border: none;
  border-radius: 5px;
}
/* ---------------------------------------------------
    FORM STYLE
----------------------------------------------------- */

/* Search bar */
input[type='text'] {
  background-repeat: no-repeat;
  -webkit-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

input[type='text']:focus {
  width: 100%;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c4c4c4;
  font-size: 12px;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #c4c4c4;
  font-size: 12px;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #c4c4c4;
  font-size: 12px;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #c4c4c4;
  font-size: 12px;
}
.custom-select:focus {
  border-color: $greener;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(150, 198, 61, 0.381);
}

/* Fixing form input issues */

.search-icon-fix {
  position: relative;
  right: -25px;
  top: 13px;
}

.form-align {
  position: relative;
  right: 10%;
  z-index: 1;
}

.form-check-input {
  position: absolute !important;
}

.form-check-label {
  margin-left: 50%;
}

.form-control:active,
.form-control:focus {
  border-color: $greener;
  box-shadow: 0 0 0 0.2rem #a2d83f6a;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  align-items: stretch;
  width: 100% !important;
}

/* #sidebar {
  min-width: 180px;
  max-width: 180px;
  background: #f2f2f2;
  color: #989898;
  transition: all 0.5s;
  font-size: 13px;
  font-weight: 600;
  z-index: 1;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.1);
} */

#sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  min-width: 180px;
  max-width: 180px;
  background: #f2f2f2;
  color: #989898;
  z-index: 999;
  transition: all 0.5s;
  font-size: 13px;
  font-weight: 600;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.05);
  overflow-x: hidden;
}

.sidebar-header {
  padding: 5%;
}

.sidebar-toggle {
  margin: 1.5%;
  position: fixed;
  z-index: 1;
  bottom: 35%;
}

.push-down {
  position: relative;
  top: 3em;
  left: 0;
}

#sidebar.active {
  min-width: 80px;
  max-width: 80px;
  overflow-x: hidden;
}

#sidebar .sidebar-header .downlogo {
  display: none;
}

#sidebar.active .sidebar-header .downlogo {
  display: block;
}

#sidebar.active .sidebar-header .toplogo {
  display: none;
}

#sidebar.active ul li.bglight {
  padding: 10px 10px;
  font-size: 0.9em;
}

.switch #sidebar ul li a {
  text-align: left;
}

#sidebar.active ul li a {
  padding: 10px 10px;
  font-size: 0.9em;
  text-align: center;
}

#sidebar.active ul li a i {
  margin-right: 0;
  display: block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

#sidebar.active ul li span {
  margin-left: 0;
}

#sidebar.active ul ul a {
  padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
  top: auto;
  bottom: 10px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

#sidebar {
  color: #989898;
  background: #f2f2f2;
}

#sidebar .sidebar-header strong {
  display: none;
  font-size: 1.8em;
}

.components {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#sidebar ul.components {
  padding: 10px 0;
}

#sidebar ul li a {
  padding: 8%;
  font-size: 13px;
  display: block;
}

#sidebar ul li span {
  margin-left: 10%;
}

#sidebar ul li a:hover,
#sidebar ul li a:active,
#sidebar ul li a:focus {
  background: #fff;
}

#sidebar ul li a i {
  margin-right: 10px;
}

#sidebar ul li.active > a,
a[aria-expanded='true'] {
  color: #fff;
  background: inherit;
}

a[data-toggle='collapse'] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: inherit;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: inherit;
}

a.article,
a.article:hover {
  background: inherit;
  color: #fff !important;
}

/* Menu dropdown Styling */

#sidebar ul li.active > a,
a[aria-expanded='true'] {
  color: #fff;
  background: transparent;
}

.dropdown-toggle::after {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dropdown-item:hover {
  color: #fff;
  text-decoration: none;
  background-color: $greener !important;
}

dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: $greener !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: $body-text;
  text-decoration: none;
  background-color: transparent;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

/* #content {
  width: 100%;
  padding: 0.3%;
  min-height: 100vh;
  
} */

#content {
  width: calc(100% - 180px);
  padding: 10px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.7s;
  background-color: #f2f2f2;
}

#content.active {
  width: calc(100% - 80px);
}

.content {
  width: calc(100% - 15%);
  position: absolute;
  top: 0;
  right: 0;
}

.nav-round {
  border-radius: 50%;
  border: 1px solid #dedede;
}
.main-content {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media screen and (max-width: 1200px) {
  div.inner-sidebar {
    position: initial;
    border-radius: 5px 5px 0 0;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }
  .inner-sidebar__header {
    border-right: 1px solid white;
    padding: 0.5rem;
    padding-right: 1.5rem;
    display: flex;
    align-items: center;
  }
  ul.inner-sidebar__nav {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    justify-content: space-around;
    align-items: baseline;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
  }
  .inner-sidebar__nav li > a {
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.65rem;
  }
  .inner-sidebar__nav li > a > img {
    margin-right: 0;
  }
  .main-content {
    padding: 0;
  }
  div.content {
    position: initial;
    padding: 0 !important;
    flex-grow: 1;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 12px;
  }

  #content {
    width: calc(100% - 85px);
    padding: 10px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.7s;
    background-color: #f2f2f2;
  }

  #sidebar {
    min-width: 85px;
    max-width: 85px;
    padding: 1%;
    text-align: center;
    z-index: 1;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }

  .sidebar-header {
    padding: 5%;
  }

  #sidebar.active {
    min-width: 85px;
    padding: 1%;
  }
  #sidebar .sidebar-header .downlogo {
    display: block;
    margin-left: 14%;
  }

  #sidebar .sidebar-header .toplogo {
    display: none;
  }
  #sidebar.active .sidebar-header .downlogo {
    display: block;
  }

  #sidebar.active .sidebar-header .toplogo {
    display: none;
  }
  #sidebar ul li a {
    padding: 10px 5px;
  }
  #sidebar ul li a span {
    font-size: 0.85em;
  }
  #sidebar ul li a i {
    margin-right: 0;
    display: block;
  }
  #sidebar ul ul a {
    padding: 10px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #sidebar ul li a i {
    font-size: 1.3em;
  }

  #sidebarCollapse span {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .bg-field {
    background: white;
  }
}

@media screen and (max-width: 600px) {
  .inner-sidebar__header {
    display: none;
  }
  button.btn-revenue > i.fas.fa-plus,
  a.btn.btn-revenue > i.fa-plus {
    margin-right: 0 !important;
  }
  button.btn-revenue > i.fa-plus + span,
  a.btn.btn-revenue > i.fa-plus + span {
    display: none;
  }
}

.sidebr-toggle {
  position: absolute;
  bottom: 50%;
}

.card-header {
  /* border-radius: 10px 10px 0px 0px !important; */
  color: #f2f2f2;
  padding: 0.35rem 1.25rem;
}

.cursor {
  cursor: pointer;
  transform: scale(1);
}

/* Styling the Bar Chart */
.ct-series-a .ct-bar {
  font-size: 10px !important;
  /* Colour of your bars */
  stroke: #c4c4c4 !important;
  /* The width of your bars */
  stroke-width: 8px;
  /* Maybe you like round corners on your bars? */
  stroke-linecap: round;
}

/* Styling the Pie Chart */
.ct-series-a .ct-slice-donut {
  /* give the donut slice a custom colour */
  stroke: red !important;
  /* customize stroke width of the donut slices in CSS. Note that this property is already set in JavaScript and label positioning also relies on this. In the right situation though it can be very useful to style this property. You need to use !important to override the style attribute */
  stroke-width: 300px !important;
  /* create modern looking rounded donut charts */
  stroke-linecap: round;
}

/* Dropdown Profile Links Styling */

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu a:hover,
.dropdown-menu a:focus,
.dropdown-menu a:active {
  -webkit-box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(154, 202, 63, 0.9);
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(154, 202, 63, 0.9);
  background-color: #00a650;
  color: #ffffff;
}

a:not([href]):not([tabindex]):hover {
  color: #ffffff;
}
/* .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: $body-text;
  background-color: #fff;
  border-radius: none;
  font-size: 14px;
}

.nav-pills .nav-link {
  background-color: #808080;
  color: #fff;
  border-radius: 0 !important;
  font-size: 14px;
  border-bottom: 1px solid #fff;
}



.tab-content {
  color: $body-text;
} */

.footer {
  position: absolute;
  bottom: 0;
}

/* Overview Page */
.bg-overview {
  background: linear-gradient(
      to right bottom,
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.25)
    ),
    url('../assets/overview-bg.svg');
  /* background: url("./assets/overview-bg.svg"); */
  background-size: cover;
  background-position: top;
  height: 100vh;
}

/* Resources Page */

.inner-sidebar {
  // position: fixed;
  border-radius: 5px;
  background: $greener;
  height: 97vh;
  min-width: 13%;
}

.resource:active,
.resource:hover,
.resource:focus {
  background: #8bb739;
}

.erg-bg {
  background-size: contain;
  height: auto;
  background-repeat: no-repeat;
  background-color: linear-gradient
    (
      180deg,
      rgba(0, 0, 0, 0.62) 0%,
      rgba(7, 7, 7, 0.1) 99.98%,
      rgba(17, 17, 17, 0.01) 99.99%,
      rgba(255, 255, 255, 0) 100%
    );
  background: url('../assets/erg-back1.jpg');
}

/* Farm Resources Page */

.table-header {
  background: #4f4f4f;
  color: #ffffff;
  text-align: left;
}

.map-header {
  background: #2f2f2f;
  opacity: 0.9;
  padding: 0.5%;
}

.center {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Chat Section */
.message-admin {
  background: linear-gradient(0deg, #ffffff, #ffffff), linear-gradient(0deg, #f8f8f8, #f8f8f8),
    linear-gradient(0deg, #fafafa, #fafafa), #dedede;
  border: 1px solid#c4c4c4;
  border-radius: 0px 20px 10px 30px;
  padding: 5%;
}

.message-user {
  background: linear-gradient(0deg, #f8f8f8, #f8f8f8), linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #fafafa, #fafafa), #dedede;
  border: 1px solid#c4c4c4;
  border-radius: 20px 0px 30px 10px;
  padding: 5%;
}
.time-stamp {
  background: linear-gradient(0deg, #f8f8f8, #f8f8f8), linear-gradient(0deg, #c4c4c4, #c4c4c4),
    linear-gradient(0deg, #808080, #808080), linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #f2f2f2, #f2f2f2), linear-gradient(0deg, $body-text, $body-text),
    linear-gradient(0deg, #333333, #333333), linear-gradient(90deg, #333333 0%, $body-text 100%);
  border-radius: 5px;
  text-align: center;
  margin: 2%;
}

/* ---------------------------------------------------
    LIST  STYLE
----------------------------------------------------- */
.list-group-item.active {
  color: $body-text;
  background-color: #f2f2f2;
  border-color: $greener;
}

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

// .ListItemEven {
//   background-color: #f8f8f0;
// }

/* Scroll Bar */

/* .revenue-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
} */

.scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 50px;
  margin-top: 5rem;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  height: 50px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #bcc0bc),
    color-stop(0.72, #d1d1d0),
    color-stop(0.86, #b4b4b4)
  );
}

.revenue-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: inherit;
}

.revenue-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, #8bb739),
    color-stop(0.72, $greener),
    color-stop(0.86, #00a650)
  );
}

@media screen and (min-width: 1440px) {
  a[data-error-button] {
    width: 100%;
    display: block;
  }
}

.bg-clip {
  /* background-image: url('../assets/erg-back1.jpg'); */
  background-position: left;
  background-repeat: no-repeat;
  clip-path: polygon(0 8%, 0% 100%, 55% 100%);
}

/*
* SWITCH *
*/
.switch {
  cursor: pointer;
}
.switch input {
  display: none;
}
.switch input + span {
  width: 48px;
  height: 28px;
  border-radius: 14px;
  transition: all 0.3s ease;
  display: block;
  position: relative;
  background: #666666;
  box-shadow: 0 8px 16px -1px rgba(102, 102, 102, 0.2);
}
.switch input + span:before,
.switch input + span:after {
  content: '';
  display: block;
  position: absolute;
  transition: all 0.3s ease;
}
.switch input + span:before {
  top: 5px;
  left: 5px;
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 5px solid #fff;
}
.switch input + span:after {
  top: 5px;
  left: 32px;
  width: 6px;
  height: 18px;
  border-radius: 40%;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  background: #fff;
  opacity: 0;
}
.switch input + span:active {
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
}
.switch input:checked + span {
  background: $greener;
  box-shadow: 0 8px 16px -1px rgba(72, 234, 139, 0.2);
}
.switch input:checked + span:before {
  width: 0px;
  border-radius: 3px;
  margin-left: 27px;
  border-width: 3px;
  background: #fff;
}
.switch input:checked + span:after {
  -webkit-animation: blobChecked 0.35s linear forwards 0.2s;
  animation: blobChecked 0.35s linear forwards 0.2s;
}
.switch input:not(:checked) + span:before {
  -webkit-animation: blob 0.85s linear forwards 0.2s;
  animation: blob 0.85s linear forwards 0.2s;
}

/* ---------------------------------------------------
    ANIMATIONS
----------------------------------------------------- */

@-webkit-keyframes blob {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.12, 0.94);
    transform: scale(1.12, 0.94);
  }
  60% {
    -webkit-transform: scale(0.96, 1.06);
    transform: scale(0.96, 1.06);
  }
}

@keyframes blob {
  0%,
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  30% {
    -webkit-transform: scale(1.12, 0.94);
    transform: scale(1.12, 0.94);
  }
  60% {
    -webkit-transform: scale(0.96, 1.06);
    transform: scale(0.96, 1.06);
  }
}
@-webkit-keyframes blobChecked {
  0% {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  30% {
    -webkit-transform: scaleX(1.44);
    transform: scaleX(1.44);
  }
  70% {
    -webkit-transform: scaleX(1.18);
    transform: scaleX(1.18);
  }
  50%,
  99% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 0;
  }
}
@keyframes blobChecked {
  0% {
    opacity: 1;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  30% {
    -webkit-transform: scaleX(1.44);
    transform: scaleX(1.44);
  }
  70% {
    -webkit-transform: scaleX(1.18);
    transform: scaleX(1.18);
  }
  50%,
  99% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 0;
  }
}
