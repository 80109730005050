.wrapper,
.item {
  border-radius: 10px;
  background-color: #f2f2f2;
}

.wrapper:hover,
.item:hover {
  background-color: #dedede;
}

.bg-secondary {
  background-color: #f2f2f2 !important;
}

.text-large {
  font-size: 18px !important;
}

.text-secondary {
  color: #2f2f2f !important;
}

.menuIconWrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.menuIconWrapper:hover {
  background-color: #f2f2f2;
}

.border-radius {
  border-radius: 200px !important;
}

.drop-shadow {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}
