.underline-green {
  border-bottom: 2px solid #50d636;
}
.underline-blue {
  border-bottom: 2px solid #000f9b;
}
.underline-yellow {
  border-bottom: 2px solid #e9e50c;
}
.sideline {
  margin-bottom: 4px;
  margin-left: 5px;
  border: 0.75px solid rgb(52, 58, 64, 0.75);
}
.sideline-sp {
  width: 75%;
}
.sideline-farm {
  width: 83%;
}
.sideline-comm {
  width: 78.5%;
}
.sideline-agro {
  width: 75.5%;
}
.pie-chart__container {
  padding: 7.5px 0;
  margin: 2.5px;
  display: flex;
}
.pie-chart__legend {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
}
.doughnut + p,
.pie-chart__legend label {
  font-size: 11px;
}
.pie-chart__badge {
  border-radius: 50%;
  height: 20px;
  margin: 0 2px;
}
.pie-chart__badge-mini {
  border-radius: 50%;
  height: 10px;
  margin: 2.5px 1px;
}
.pie-chart__badge-mini::after {
  content: '';
  padding: 1px 5px;
}
.pie-chart__badge::after {
  content: '';
  padding: 2px 10px;
}
.badge__allocated {
  background-color: linear-gradient(0deg, #2a91cb, #2a91cb), #4c1fff;
}
.badge__distributed {
  background-color: #96c63d;
}
.badge__undistributed {
  background-color: #2f80ed;
}
.badge__in-store {
  background-color: #cecece;
}
.doughnut {
  border: 7px solid;
  border-radius: 50%;
  width: 2px;
  padding: 5px 5px;
}
.doughnut__yellow {
  border-color: #febd33;
}
.doughnut__blue {
  border-color: #2f80ed;
}
.doughnut__green {
  border-color: #96c63d;
}
.soil-pod {
  border-radius: 30%;
  padding-top: 15px;
  padding-bottom: 10px;
  background: linear-gradient(
    180deg,
    rgba(241, 241, 241, 0.5),
    rgba(184, 184, 184, 0.75)
  );
}
.soil-pod__container {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}
.fix-height-75 {
  height: 75vh;
}
