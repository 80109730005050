.hidden {
  display: none;
}

.MuiPaper-root {
  background-color: white !important;
}

#register,
#login {
  width: 300px;
  border: 1px solid #d6d7da;
  padding: 0px 15px 15px 15px;
  border-radius: 5px;
  font-family: arial;
  line-height: 16px;
  color: #333333;
  font-size: 14px;
  background: #ffffff;
  margin: 100px auto;
}

form label,
form input {
  display: block;
  margin-bottom: 10px;
  width: 90%;
}

form input {
  padding: 10px;
  border: solid 0.5px #bdc7d8;
  border-radius: 7px;
}

.button {
  background-color: #00bfff;
  border-color: #3ac162;
  font-weight: bold;
  padding: 12px 15px;
  color: #ffffff;
}

.errorMsg {
  color: #cc0000;
  margin-bottom: 12px;
}
.redcolor {
  color: #cc0000;
}
.green {
  color: #3ac162;
}

.sucessMsg {
  color: #6b8e23;
  margin-bottom: 10px;
}

.left-sidebar {
  height: 90vh;
  flex-grow: 1;
}

.right-sidebar {
  margin-left: 15%;
  flex-grow: 2;
}

.wallet-content {
  width: calc(100% - 17%);
  position: absolute;
  top: 19%;
  right: 0;
}

.card-text {
  font-size: 18px;
  font-weight: bold;
}

@media (min-width: 768px) {
  .right-sidebar {
    margin: 0;
  }
}

@media (min-width: 576px) {
  .right-sidebar {
    margin: 0;
  }
}

@media (max-width: 1200px) {
  .wallet-content {
    width: 100%;
    top: 23%;
  }
  ul.inner-sidebar__nav {
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    justify-content: flex-start;
  }
  .components {
    justify-content: start;
  }
}
