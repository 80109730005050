.pointer {
  cursor: pointer;
}
.hidden {
  display: none;
}
.bg-list-outer:hover {
  background: #dedede;
}

.bg-list-inner {
  background: #f7f7f7;
}
